import { errorNotification, successNotification } from './helpers/toastify.js';

import { generateMessAppointment } from './helpers/helpers';

// Функція для відкриття модального вікна
export function openAppointmentModal() {
  const appointmentModal = document.getElementById(
    'appointment-modal-backdrop'
  );
  if (!appointmentModal) {
    console.error('Modal not found');
    return;
  }
  document.body.style.overflow = 'hidden';
  appointmentModal.style.display = 'block';
}

// Закриття модального вікна
function closeModal() {
  const appointmentModal = document.getElementById(
    'appointment-modal-backdrop'
  );
  if (!appointmentModal) {
    console.error('Modal not found');
    return;
  }
  document.body.style.overflow = 'auto';
  appointmentModal.style.display = 'none';
}

// Відправка форми
const handleSubmit = async e => {
  e.preventDefault();

  const formData = {
    name: e.target.name.value,
    phone: e.target.phone.value,
    comment: e.target.message.value,
  };

  try {
    await fetch('tel.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        text: generateMessAppointment(formData),
      }),
    }).then(successNotification);

    e.target.reset();
    closeModal();
  } catch (error) {
    errorNotification();
  }
};

// Хендлер для кліку по кнопці закриття модалки
document
  .getElementById('appointment-modal-close')
  .addEventListener('click', function () {
    closeModal();
  });

// Закриття модального вікна при кліку по бекдропу
document
  .getElementById('appointment-modal-backdrop')
  .addEventListener('click', function (event) {
    if (event.target === this) {
      closeModal();
    }
  });

// Закриття модалки при натисканні на клавішу ESC
document.addEventListener('keydown', function (event) {
  if (event.key === 'Escape') {
    closeModal();
  }
});

//Подія відправки форми
document
  .getElementById('appointment-form')
  .addEventListener('submit', handleSubmit);
